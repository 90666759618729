@import '~antd/dist/antd.css';
@import 'antd-override.css';
@import 'common/index.css';
@import 'model-registry/index.css';

body {
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}
