div.artifact-view {
  height: 690px;
  display: flex;
  overflow: hidden;
}

.artifact-left {
  min-width: 200px;
  max-width: 400px;
  flex: 1;
}

.artifact-left li {
  white-space: nowrap;
}

.artifact-right {
  flex: 3;
  min-width: 400px;
  max-width: calc(100% - 200px); /* 200px is the min-width of .artifact-left */

  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.artifact-info-left {
  flex: 1;
  max-width: 75%;
}
.artifact-info-right {
  margin-left: auto;
}

.artifact-info-path {
  display: flex;
  align-items: center;
}

.artifact-info-text {
  min-width: 0;
}

.artifact-info-link {
  height: 40px;
  padding-top: 5px;
  font-size: 21px;
}

.artifact-info-size {
  overflow: hidden;
  text-overflow: ellipsis;
}

.view-button {
  margin-top: 6px;
}

.empty-artifact-outer-container {
  height: 558px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #ccc;
  background-color: #fafafa;
}

.empty-artifact-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-artifacts {
  width: 255px;
  font-size: 24px;
  margin: 0 auto;
}

.no-artifacts-info {
  font-size: 16px;
  padding-top: 8px;
  color: #888888;
}

.loading-spinner {
  height: 20px;
  opacity: 0;
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.artifact-info-right .model-version-link {
  display: flex;
  align-items: baseline;
  max-width: 140px;
  padding-top: 1px;
  padding-left: 4px;
}

.artifact-info-right .model-version-link .model-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.artifact-info-right .model-version-info {
  font-size: 12px;
}

.artifact-info-right .model-version-info .model-version-link-section {
  display: flex;
  align-items: center;
}

.artifact-info-right .model-version-info .model-version-status-text {
  overflow: hidden;
  max-width: 160px;
  text-overflow: ellipsis;
}
